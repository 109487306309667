import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import './topOffer.css'
import ConversionEvents from '../ConversionEvents/ConversionEvents';

// Define a mapping of device names to their corresponding image paths
const deviceImages = {
  macos: '/macos.png',
  windows: '/windows.png',
  android: '/androidicon.svg',
  iphone: '/iphoneicon.png',
  ipad: '/ipad.svg',
  ios: '/ios.png', // Add ios.png for iPad and iPhone combined
};

/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
const TopOffer = ({
    // eslint-disable-next-line react/prop-types
    title_text,
    center_text,
    image_game,
    devices,
    link,
    reward_payout,
    events
  }) => {
  
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const truncatedReward = parseFloat(reward_payout).toFixed(1);

  // Determine which device icons to display
  const renderDeviceIcons = () => {
    const hasIpad = devices.includes('ipad');
    const hasIphone = devices.includes('iphone');

    // Check if both iPad and iPhone are present
    if (hasIpad && hasIphone) {
      return <img key="ios" src={deviceImages.ios} alt="iOS icon" width={30} />;
    }

    return devices.map(device => (
      deviceImages[device] ? (
        <img key={device} src={deviceImages[device]} alt={`${device} icon`} width={30} />
      ) : null
    ));
  };

  return (
    <>
      <div className="carousel-container">
        <div className="card" onClick={openModal}>
          <div className="first">
            <div className="gameLogo">
              <img src={image_game} alt="" width={100} />
            </div>
            {devices &&<div className="device-icons">
              {renderDeviceIcons()}
            </div>}
          </div>
          <div className="second">
            <div className="heading">
              <h1 className="poppins-bold">{title_text}</h1>
            </div>
            <div className="level">
              <p className="poppins-semibold">{center_text}</p>
            </div>
          </div>
          <div className="third" onClick={openModal}>
            <div>
              <p className="poppins-bold">+{truncatedReward}</p>
            </div>
          </div>
        </div>
      </div>

      {showModal && ReactDOM.createPortal(
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>×</button>
            <ConversionEvents
              description={center_text}
              events={events}
              totalReward={truncatedReward}
              link={link}
            />
          </div>
        </div>,
        document.body
      )}
    </>
  );
};
  
export default TopOffer;