import React from 'react';
import classes from './conversionEvents.module.css';

const ConversionEvents = ({ description, events, totalReward, link }) => {
  return (
    <div className={classes.rewardProgressContainer}>
      <div className={classes.rewardHeader}>
        {description}
      </div>
      <div className={classes.rewardContent}>
        <div className={classes.eventList}>
          {events.map((event, index) => (
            <div key={index} className={classes.eventItem}>
              <div className={classes.eventIconContainer}>
                <div className={classes.eventIcon}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                {index !== events.length - 1 && <div className={classes.eventLine}/>}
              </div>
              <div className={classes.eventDetails}>
                <div className={classes.eventDescription}>
                  {event.value && (
                    <span className={classes.eventValue}>{parseFloat(event.value).toFixed(1)}</span>
                  )}
                  <span className={classes.eventText}>{event.eventDescription}</span>
                </div>
                {event.subDescription && (
                  <p className={classes.eventSubDescription}>{event.subDescription}</p>
                )}
              </div>
            </div>
          ))}
        </div>
        <a href={link} className={classes.rewardButton} target="_blank">
          Earn {totalReward} coins
        </a>
      </div>
    </div>
  );
};

export default ConversionEvents;