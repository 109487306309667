import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Button from '../../components/Button/Button';
import classes from './offersPage.module.css';

const CategorySelector = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('Explore');

  // Define categories with their routes
  const categories = [
    { name: 'Explore', route: '/tasks' },
    { name: 'Games', route: '/games' },
    { name: 'Surveys', route: '/surveys' },
    { name: 'Offers', route: '/offers' }
  ];

  // Check screen size and update isMobile state
  useEffect(() => {
    const checkMobileScreen = () => {
      setIsMobile(window.innerWidth <= 1280);
    };

    // Check initial screen size
    checkMobileScreen();

    // Add event listener for resize
    window.addEventListener('resize', checkMobileScreen);

    // Cleanup listener
    return () => window.removeEventListener('resize', checkMobileScreen);
  }, []);

  // Determine current category based on current path
  useEffect(() => {
    const path = window.location.pathname;
    const matchedCategory = categories.find(cat => cat.route === path);
    if (matchedCategory) {
      setCurrentCategory(matchedCategory.name);
    }
  }, []);

  // Handle category selection
  const handleCategorySelect = (category) => {
    setCurrentCategory(category.name);
    setIsDropdownOpen(false);
    window.location.href = category.route;
  };

  // Mobile Dropdown View
  const MobileDropdown = () => (
    <div className={classes.mobileDropdownContainer}>
      <div 
        className={classes.dropdownHeader} 
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span className={classes.dropdownCurrentCategory}>{currentCategory}</span>
        {isDropdownOpen ? (
          <ChevronUp color="white" size={20} />
        ) : (
          <ChevronDown color="white" size={20} />
        )}
      </div>
      {isDropdownOpen && (
        <div className={classes.dropdownMenu}>
          {categories
            .filter(cat => cat.name !== currentCategory)
            .map((category, index) => (
              <div
                key={category.name}
                className={`${classes.dropdownMenuItem} ${
                  index === 0 ? classes.firstItem : ''
                } ${
                  index === categories.length - 2 ? classes.lastItem : ''
                }`}
                onClick={() => handleCategorySelect(category)}
              >
                {category.name}
              </div>
            ))
          }
        </div>
      )}
    </div>
  );

  // Desktop Button Row
  const DesktopButtons = () => (
    <div className={classes.categories}>
      {categories.map(category => (
        <Button 
          key={category.name}
          className={classes.button} 
          text={category.name}
          color={category.name === currentCategory ? "black" : "white"}
          backgroundColor={category.name === currentCategory ? "white" : "black"}
          width="8rem" 
          height="2rem" 
          fontSize="1rem"
          onClick={() => handleCategorySelect(category)}
        />
      ))}
    </div>
  );

  return isMobile ? <MobileDropdown /> : <DesktopButtons />;
};

export default CategorySelector;