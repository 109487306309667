import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './header.module.css';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import { FiChevronDown } from 'react-icons/fi';

export default function Header() {
    const { user, logout } = useAuth();
    const [coins, setCoins] = useState(user?.coins || 0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const pollData = async () => {
            if (user) {
                const response = await axios.get(`https://www.rewardrocket.app/api/users/coins-count?user_id=${user.id}`);
                setCoins(response.data.coins);
            }
        };

        const intervalId = setInterval(pollData, 5000);
        return () => clearInterval(intervalId);
    }, [user]);

    return (
        <header className={classes.header}>
            <div className={classes.container}>
                <Link to="/" className={classes.logo}>
                    <img src={`/Xbznpd01.svg`} alt={'logo'} />
                </Link>
                {isMobile ? (
                    <div className={classes.mobileMenu}>
                        <Link to="/redeem" className={classes.coinLogo}>
                            <img src={`/purplecoin.svg`} alt={'purplecoin'} />
                            <span className={classes.coin_count}>{coins}</span>
                        </Link>
                        <div className={classes.dropdown}>
                            <button className={classes.dropdownButton}>
                                Menu <FiChevronDown />
                            </button>
                            <div className={classes.dropdownContent}>
                                {user && <Link to="/tasks">Tasks</Link>}
                                {user && <Link to="/redeem">Redeem</Link>}
                                {user && <a onClick={logout}>Logout</a>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <nav>
                        <ul>
                            {user && <Link to="/tasks">Tasks</Link>}
                            {user && <Link to="/redeem">Redeem</Link>}
                            {user && (
                                <li>
                                    <Link to="/redeem" className={classes.coinLogo}>
                                        <img src={`/purplecoin.svg`} alt={'purplecoin'} />
                                        <span className={classes.coin_count}>{coins}</span>
                                    </Link>
                                </li>
                            )}
                            {user ? (
                                <li className={classes.menu_container}>
                                    <Link to="/profile">{user.username}</Link>
                                    <div className={classes.menu}>
                                        <a onClick={logout}>Logout</a>
                                    </div>
                                </li>
                            ) : (
                                <Link to="/login" className={classes.loginButton}>Login</Link>
                            )}
                        </ul>
                    </nav>
                )}
            </div>
        </header>
    );
}