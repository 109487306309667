import axios from "axios";

export const getLootablyGamingOffers = async (country) => {
    const { data } = await axios.get('api/lootablyoffers/gaming', {
        headers: {
            "Content-Type": 'application/json'
        },
        params: {
            country: country
        }
    });
    return data;
};

export const getLootablyOffers = async (country) => {
    const { data } = await axios.get('api/lootablyoffers/offers', {
        headers: {
            "Content-Type": 'application/json'
        },
        params: {
            country: country
        }
    });
    return data;
};