import React from 'react'
import { useAuth } from '../../hooks/useAuth'

export default function Revlum() {

    const { user } = useAuth();
  return (
    <iframe width="100%" height="800px" border="0" padding="0" margin="0" scrolling="yes" frameborder="0" src={`https://revlum.com/offerwall/54it3pp1tssla5xxmz7uh6ntvavaz7/${user.id}`}></iframe>
  )
}
