import React, { useState } from 'react';
import './wallOffer.css';
import ConversionEvents from '../ConversionEvents/ConversionEvents';

// Define a mapping of device names to their corresponding image paths
const deviceImages = {
  macos: '/macos.png',
  windows: '/windows.png',
  android: '/androidicon.svg',
  iphone: '/iphoneicon.png',
  ipad: '/ipad.svg',
  ios: '/ios.png',
};

const WallOffer = ({
  title_text,
  center_text,
  image_game,
  devices,
  link,
  reward_payout,
  events
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const truncatedReward = parseFloat(reward_payout).toFixed(1);

  const renderDeviceIcons = () => {
    const hasIpad = devices.includes('ipad');
    const hasIphone = devices.includes('iphone');

    if (hasIpad && hasIphone) {
      return <img key="ios" src={deviceImages.ios} alt="iOS icon" width={30} />;
    }

    if (devices.includes('*')) {
      return Object.keys(deviceImages).map(device => (
        device !== 'ios' && (device !== 'ipad' || !hasIphone) && (device !== 'iphone' || !hasIpad) ? (
          <img key={device} src={deviceImages[device]} alt={`${device} icon`} width={30} />
        ) : null
      ));
    } else {
      return devices.map(device => (
        deviceImages[device] ? (
          <img key={device} src={deviceImages[device]} alt={`${device} icon`} width={30} />
        ) : null
      ));
    }
  };

  return (
    <>
      <div className="card" onClick={openModal}>
        <div className="first">
          <div className="gameLogo">
            <img src={image_game} alt="" width={100} />
          </div>
          <div className="device-icon">
            {renderDeviceIcons()}
          </div>
        </div>
        <div className="second">
          <div className="heading">
            <h1 className="poppins-bold">{title_text}</h1>
          </div>
          <div className="level">
            <p className="poppins-semibold">{center_text}</p>
          </div>
        </div>
        <div className="third" onClick={openModal}>
          <div>
            <p className="poppins-bold">+{truncatedReward} coins</p>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>×</button>
            <ConversionEvents
              description={center_text}
              events={events}
              totalReward={truncatedReward}
              link={link}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WallOffer;